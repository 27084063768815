requirejs.config({
    paths: {
        cookieconsent: '../lib/cookieconsent/build/cookieconsent.min',
        jquery: '//cdn.jsdelivr.net/webjars/jquery/3.3.1/jquery.min'
    },
    shim: {
        cookieconsent: {
            exports: 'cookieconsent'
        },
        jquery: {
            exports: '$'
        }
    }
});

function checkShowBanks() {
    var selectedMethod = $("form #method").val();

    if (selectedMethod == "ideal") {
        $(".banks").show();
    } else {
        $(".banks").hide();
    }
}

function checkShowAddressInfo() {
    if (isSendToHome()) {
        $(".address-info").show();
    } else {
        $(".address-info").hide();
    }
}

function isSendToHome() {
    return $("form #orderLocationId").val() == "-1";
}

function calculateTotalAmount() {
    var numberInput = $("#numberOfBooks");
    var bookPrice = parseFloat($(numberInput).attr("dataprice"));
    var sendingExtra = parseFloat($(numberInput).attr("datasendingextra"));

    var numberOfBooks = $(numberInput).val();

    var pricePerBook = bookPrice + (isSendToHome() ? sendingExtra : 0);
    var totalAmount = numberOfBooks * pricePerBook;

    console.log(isSendToHome(), sendingExtra, pricePerBook);
    $("#totalAmount").html(totalAmount.toFixed(2).replace(".", ","));
}

require(['jquery', 'cookieconsent'], function ($, cookieconsent) {
    $(document).ready(function () {
        checkShowBanks();
        checkShowAddressInfo();
        calculateTotalAmount();

        $("form #method").on("change", checkShowBanks);
        $("form #orderLocationId").on("change", checkShowAddressInfo);
        $("form #orderLocationId").on("change", calculateTotalAmount);
        $("form #numberOfBooks").on("change", calculateTotalAmount);

        $(".mailto").each(function(index, elem) {
            console.log(elem)

            var user = $(elem).data('user');
            var domain = $(elem).data('domain');
            var email = user + '@' + domain;

            elem.href = 'mailto:' + email;

            if (!elem.innerHTML) {
                elem.innerHTML = email;
            }
        });

        cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#000"
                },
                "button": {
                    "background": "#f1d600"
                }
            }
        });
    });
});

define("main", function(){});

